import Animate from 'components/atoms/animate'
import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import ArticlesList from 'components/organisms/articlesList'
import CommunityNav from 'components/organisms/communityNav'
import CommunitySearchBar from 'components/organisms/communitySearchBar'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import React, { useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { H1Style } from 'styles/base'

const CategoryHeader = styled(Container)`
  ${({ theme }): CSSProp => css`
    margin: 2.4rem auto;

    @media only screen and ${theme.breakpoints.toNormalScreen} {
      position: relative;
      padding-bottom: 2.4rem;
      margin-bottom: 4rem;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 2rem;
        right: 2rem;
        height: 1px;
        background-color: ${theme.colours.primary};
      }
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 6rem auto;
    }

    h1 {
      ${H1Style}
    }

    > div {
      max-width: 55rem;
      font-size: ${theme.font.fontSizeMid};
      font-weight: ${theme.font.fontWeightLight};

      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: ${theme.font.fontSizeLarge};
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: ${theme.font.fontSizeBodyMobile};
      }
    }
  `}
`

const TopArticles = styled.div`
  ${({ theme }): CSSProp => css`
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 5rem;
    margin-bottom: 5rem;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 7rem;
    }
  `}
`

type communityCategoryTemplateProps = PageProps & {
  data: {
    contentfulArticleCategory: {
      id: string
      title: string
      slug: string
      node_locale: string
      description: { description: string }
    }
    articles: {
      nodes: {
        id: string
        title: string
        slug: string
        node_locale: string
        tags: [
          {
            id: string
            slug: string
            title: string
            internal: { type: string }
          }
        ]
        category: { id: string; slug: string; title: string }
      }
      totalCount: number
    }
    featuredArticles: {
      nodes: {
        id: string
        title: string
        slug: string
        node_locale: string
        tags: [
          {
            id: string
            slug: string
            title: string
            internal: { type: string }
          }
        ]
        category: { id: string; slug: string; title: string }
      }
      totalCount: number
    }
  }
  pageContext: {
    featuredArticleSlugs: string[]
  }
}

const communityCategoryTemplate = (
  props: communityCategoryTemplateProps
): JSX.Element => {
  const [searchBarShowing, setSearchBarShowing] = useState(false)
  const { featuredArticleSlugs } = props.pageContext
  const {
    contentfulArticleCategory,
    articles: articlesData,
    featuredArticles,
  } = props.data
  const localFeaturedArticles = getLocalNodes(featuredArticles.nodes)
  const articles = getLocalNodes(articlesData.nodes)
  const { title, description, slug } = contentfulArticleCategory

  return (
    <Layout>
      <Seo
        title={title}
        description={description?.description}
        url={props.location.href}
      />
      {searchBarShowing ? (
        <CommunitySearchBar close={(): void => setSearchBarShowing(false)} />
      ) : (
        <CommunityNav toggleSearch={(): void => setSearchBarShowing(true)} />
      )}

      <Animate>
        <CategoryHeader>
          <h1>{title}</h1>
          <div>{description?.description}</div>
        </CategoryHeader>
      </Animate>

      <Container>
        <Animate>
          <TopArticles>
            {localFeaturedArticles.map(article => (
              <Card
                key={article.id}
                title={article.title}
                slug={`/community/${article.slug}`}
                subTitle={{
                  title: article.category?.title,
                  slug: article.category?.slug,
                }}
                image={article.heroImage}
                tags={article.tags}
              />
            ))}
          </TopArticles>
        </Animate>
        <ArticlesList
          articles={articles}
          categorySlug={slug}
          more={articlesData.totalCount / 2 > articles.length}
          skip={11}
          exclude={featuredArticleSlugs}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query contentfulArticleCategory(
    $slug: String
    $locale: String
    $featuredArticleSlugs: [String]
  ) {
    contentfulArticleCategory(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      id
      title
      slug
      node_locale
      description {
        description
      }
    }
    articles: allContentfulArticle(
      limit: 22
      filter: {
        category: { slug: { eq: $slug } }
        slug: { nin: $featuredArticleSlugs }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        title
        node_locale
        slug
        featured
        category {
          id
          title
          slug
        }
        tags {
          id
          title
          slug
        }
        body {
          raw
        }
        metaDescription {
          metaDescription
        }
        heroImage {
          title
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        internal {
          type
        }
      }
      totalCount
    }
    featuredArticles: allContentfulArticle(
      filter: { slug: { in: $featuredArticleSlugs } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        title
        node_locale
        slug
        featured
        category {
          id
          title
          slug
        }
        tags {
          id
          title
          slug
        }
        body {
          raw
        }
        metaDescription {
          metaDescription
        }
        heroImage {
          title
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        internal {
          type
        }
      }
      totalCount
    }
  }
`

export default communityCategoryTemplate
