import styled, { css } from 'styled-components'

import theme from '../components/atoms/theme'

const { breakpoints } = theme

export const H1Style = css`
  font-family: ${theme.font.fontFamilyAlt};
  font-size: 2.2rem;
  line-height: 1.5;
  /* font-weight: ${theme.font.fontWeightNormal}; */
  @media only screen and ${theme.breakpoints.fromNormalScreen} {
    font-size: 2.8rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    font-size: 3.8rem;
    line-height: 1.37;
  }
`
export const H1 = styled.h1`
  ${H1Style}
`

export const H2Style = css`
  font-weight: 900;
  margin-bottom: 1.6rem;
  line-height: 1.2;
  font-size: 2.4rem;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 3.6rem;
  }
`
export const H2 = styled.h2`
  ${H2Style}
`

export const H3Style = css`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`
export const H3 = styled.h3`
  ${H3Style}
`

export const H4Style = css`
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 1.5;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.8rem;
  }
`
export const H4 = styled.h4`
  ${H4Style}
`

export const H5Style = css`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
  line-height: 1.5;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.6rem;
  }
`
export const H5 = styled.h5`
  ${H4Style}
`

export const H6Style = css`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
  line-height: 1.4;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.4rem;
  }
`
export const H6 = styled.h6`
  ${H6Style}
`